import Card from "./card";
import styled from '@emotion/styled';
import React,{useEffect,useState} from "react"
import { motion } from "framer-motion";
//import { SpringGrid, makeResponsive } from 'react-stonecutter';
//const Grid = measureItems(SpringGrid);
//const Grid = makeResponsive(SpringGrid, { maxWidth: 1920 })
import StackGrid from "react-stack-grid";
import PeopleCard from './peopleCard';
import { useWindowSize } from "../../utils/hooks";

const StyledList = styled(motion.div)`
    display:inline-flex;
    flex-wrap: wrap;
    //background-color:red;
    margin: 0 -10px;
  width: calc(100% + 20px);
`
const StyledListItem = styled(motion.div)`
    //width: calc(100% / 3 );
    //margin: 5px 5px;
    //margin-bottom: 20px;

   ${props => props.isOpen && `
   z-index:2;
  `}
`
const container = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        delayChildren: 0.5
      }
    }
  }
  
  const item = {
    hidden: { opacity: 0 },
    show: { opacity: 1 }
  }

const CardList = ({items,allTopics,filterTopic,isTeam=false,...props}) => {
    //const [cards, setCards] = useState(items);
    const [filteredItems, setFilteredItems] = useState([]);
    const [selectedIndex, setIndex] = useState(null);
    const [delayedIndex, setDelayedIndex] = useState(null);
    const [width] = useWindowSize();
    const [colWidth,setColWidth] = useState('33.33%');

    useEffect(() => {
      if(width>0){
        setColWidth(width>768 ? width>1024 ? '33.33%' : '50%' : '100%');
      }
    }, [width]);

    /*useEffect(() => {
        setTimeout(() => setCards(cards.reverse()), 1000);
      }, [cards]);*/

      useEffect(() => {
          selectedIndex===null ? setTimeout(() => setDelayedIndex(selectedIndex), 400):
          setDelayedIndex(selectedIndex);
      }, [selectedIndex]);

      useEffect(() => { try{
          setFilteredItems(filterTopic!==undefined ? [...items.filter(it=>it.category && it.category.find(c=>c.slug===filterTopic.slug))] : [...items]);
        }catch{
          setFilteredItems([...items]);
        }
    }, [filterTopic,items]);

    const onOpen = (e,index) => {
      e.preventDefault();
      e.stopPropagation();
      setIndex((selectedIndex)=>index===selectedIndex?null:index)
      console.log('open card')
    }

    return ( <div {...props}>
     {
       filteredItems && filteredItems.length>0 &&
     
     <StackGrid
        columnWidth={colWidth}
        gutterWidth={20}
        gutterHeight={20}
        enableSSR={true}
      >
        {
            filteredItems.map((card,index)=><StyledListItem 
            key={`list-item_${card.id}-${index}`} 
            isOpen={index===delayedIndex} 
            >
              {
                !isTeam ?
                <Card isOpen={index===selectedIndex} 
                onOpen={onOpen}
                isMasonry={true} 
                data={card} />
                :
                <PeopleCard index={index} isMasonry={true} data={card} /> 
              }
            </StyledListItem>)
        }
      </StackGrid>
      } 
      {/* <PeopleCard index={index} data={card.node} /> */}
    {/*
    <Grid
    style={{width:'100% !important'}}
    className="unstyled-l
ist"
  component="ul"
  //columns={3}
  defaultColumns={3}
  responsive={true}
  columnWidth={30}
  lengthUnit={'%'}
  gutterWidth={0}
  gutterHeight={0}
  itemHeight={520}
  springConfig={{ stiffness: 170, damping: 26 }}
>
{
            filteredItems.map((card,index)=><li key={`list-item_${card.id}`} isOpen={index===delayedIndex} onClick={()=>setIndex((selectedIndex)=>index===selectedIndex?null:index)}><Card isOpen={index===selectedIndex} key={`cardList_card_${index}`} layout data={card} /></li>)
        }
</Grid> 
*/}
{/* 
    <StyledList 
        variants={container}
        initial="hidden"
        animate="show">
        {
            filteredItems.map((card,index)=><StyledListItem key={`list-item_${card.id}`} isOpen={index===delayedIndex} onClick={()=>setIndex((selectedIndex)=>index===selectedIndex?null:index)}><Card isOpen={index===selectedIndex} key={`cardList_card_${index}`} layout data={card} /></StyledListItem>)
        }
    </StyledList>
  */}
    </div> );
}
 
export default CardList;