import React from "react";
import { useState } from "react";
import styled from "@emotion/styled"
import Img from 'gatsby-image'
import { Link } from 'gatsby'
import { motion } from "framer-motion";
import CardImage from './cardImage';
import CardContent from './cardContent';

const fakeGradient = [
  'linear-gradient(128deg,#40afff,#3f61ff)',
  'linear-gradient(128deg,#ff9a3f,#ff4b40)',
  'linear-gradient(128deg,#bdff53,#2bfa52)'
]
const StyledCard = styled(motion.div)`
   
    //border-radius: 8px;
    overflow:hidden;
    padding-bottom: 20px;
    margin:  ${props => props.isMasonry ? '0' : '0 10px'};
    position:relative;
    //border: 10px solid white;
    height:500px;
    background-image: linear-gradient(to bottom, #F8F8F8, #EFEFEF);
    display:flex;
    align-items:center;
    flex-direction:column;
    color:#000;
  ${props => props.isHighlight && `
    background-image: linear-gradient(to bottom, #caf5ff, #0281C8);
    color:#fff;
    .read-more:hover {
      color: white;
      opacity: .8;
    }
    
  `}



${props => props.isOpen && `
    width: 100vw;
    height:100vh;
    max-width: 1400px;
    max-height: 800px;
    
    position:fixed;
    top: 0;left:0;
  `}
  
`

const cardVariants = {
}

const Card = ({ data,isOpen, isMasonry=false,onOpen,id,...props }) => {
  const isHighlight = data.highlight;

  return (
   
      <StyledCard isMasonry={isMasonry} isOpen={isOpen} variants={cardVariants} layout isHighlight={isHighlight}>
        <CardContent isOpen={isOpen} onOpen={onOpen} data={data} id={id} layoutId={`card-content-${id}`} />
      </StyledCard>

  )
}
export default Card;
