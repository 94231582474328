import React from "react";
import { useState } from "react";
import styled from "@emotion/styled"
import Img from 'gatsby-image'
import { theme } from '../../config/theme';
import MailIcon from '../icons/mailIcon'
import { useShowModal } from "../modal/modalContext";
import LinkedInIcon from '../icons/linkedInIcon';


const fakeGradient = [
  'linear-gradient(128deg,#40afff,#3f61ff)',
  'linear-gradient(128deg,#ff9a3f,#ff4b40)',
  'linear-gradient(128deg,#bdff53,#2bfa52)'
]
const StyledCard = styled.div`
    background: #F8F8F8;
    //border-radius: 8px;
    overflow:hidden;
    text-align:center;
    padding-bottom: 0px;
    margin:  ${props => props.isMasonry ? '0' : '0 10px'};
    height:600px;
  ${props=>props.useGradient && `
    background-image: linear-gradient(to bottom, #caf5ff, #00a0d6);
    color:#fff;
  `}

  /*.card-image {
  transition: transform 0.3s ease-out;
  }
  &:hover .card-image {
    transform: scale(1.02);
  }*/
  &.is-over h3 {
    color: ${theme.colors.primary};
  }
  
  /*transition: transform .2s cubic-bezier(0.075, 0.82, 0.165, 1);
  &:hover{
    transform: scale(1.005);
  }*/
  
`
const CardContent = styled.div`
    padding: 10px 2em;
    h3{
      font-weight: 900;
      font-size: 1.7em;
      margin: 20px 0 0;
      font-style:italic;
      &:hover {
  cursor:pointer;
  color: ${theme.colors.primary};
}
    }
    h4 {
      font-size: 1.6em;
      margin: 0;
      font-weight: 300;
      font-style:italic;

margin-top: -9px;

    }
   
`
const CardImage = styled.div`
    height:400px;
    overflow:hidden;
    display:flex;
    align-items:flex-start;
    justify-content:center;
    .gatsby-image-wrapper {
      height: 100%;
    }
    cursor:pointer;

`
const FakeImage = styled.div`
     background-image: ${props=> fakeGradient[parseInt(props.index)]};
   width:100%;

   :after {
  content: "";
  display: block;
  padding-bottom: 100%;
}
`
const CardContact = styled.div`
/*
position:absolute;
bottom: 30px;
width: 100px;
margin-left: -50px;
font-size: 1.1em;
color: ${theme.colors.primary};
font-style:italic;
font-weight: 900;*/
display:flex;
align-items:center;
justify-content:center;
top: 30px;
position:relative;
img,svg {
  height: 19px;
  margin: 0 5px;
}
svg path {
  transition: fill .3s cubic-bezier(0.075, 0.82, 0.165, 1);
}
svg:hover{
  path{
    fill: ${theme.colors.primary};
  }
}
`

const PeopleCard = ({ data,index,isMasonry, ...props }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isOver, setIsOver] = useState(false);
    const handleShowModal = useShowModal({type:"team",data:data})
    const onOpen = (e) => {
      e.preventDefault();
      handleShowModal();
    }
    return (
      data ?
    <StyledCard className={`${isOver && 'is-over'}`} isMasonry={isMasonry}>
        <CardImage onMouseEnter={()=>setIsOver(true)} onMouseLeave={()=>setIsOver(false)}  className="card-image" onClick={onOpen}>
        {data.image ? <Img className="img-fluid" alt={`Bild: ${data.title}`} fluid={data.image.fluid} /> :
        <FakeImage index={index-2} />}
        </CardImage>
      
        <CardContent>
        <a href={`/team/${data.slug}`} onClick={onOpen}><h3>{data.name}</h3></a>
          <h4>{data.title}</h4>
        <CardContact>
          {data.linkedIn && <a className="link--icon" href={data.linkedIn} rel="noreferrer" target="_blank">
            Folge {data.name} auf LinkedIn<LinkedInIcon/></a>}
          {data.email && <a className="link--icon" href={`mailto:${data.email}`}>
            Sende E-Mail an {data.name}
            <MailIcon/>
            </a>}
        </CardContact>
         
        </CardContent>
       
       
    </StyledCard>:<></>
    )
}
export default PeopleCard;
