import styled from '@emotion/styled';
import React,{useEffect,useState} from "react"
import { motion } from "framer-motion";
import Img from 'gatsby-image'
import { useShowModal } from '../modal/modalContext';
import { theme } from '../../config/theme';

const StyledList = styled(motion.div)`
    display:flex;
    flex-wrap: wrap;
    /*margin-left: -30px;
    margin-right: -30px;
    @media (max-width: ${theme.breakpoints.m}) {
      margin-left: -20px;
    margin-right: -20px;
        }*/
   
    align-items: center;
    margin: 50px auto 0;
    padding: 0;
    > div {
        //width: calc(10% - 60px);
        //padding-top: 25%;
            flex: 0 0 25%;
        max-width: 25%;

        @media (max-width: ${theme.breakpoints.m}) {
          margin: 20px;
        }
        padding-bottom: 30px;
        text-align:center;
        overflow:hidden;
        cursor:pointer;
        img {
          max-height: 100px;
        max-width: 160px;
        @media (max-width: ${theme.breakpoints.m}) {
          max-height: 60px;
          max-width: 72px;
        }
    &.img-portrait {
      width:auto;
    }
    &.img-landscape {
      height:auto;
    }
          transition: filter .5s ease-out;
          //filter: grayscale(1) brightness(1.4);
        }
        &:hover img {
          //filter: grayscale(0) brightness(1);
        }
    }
`
const container = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        delayChildren: 0.5
      }
    }
  }
  
  const item = {
    hidden: { opacity: 0 },
    show: { opacity: 1 }
  }

  const ClientLogo = ({client}) => {
    const handleShowModal = useShowModal({type:'client',data:client})
    const onOpen = (e) => {
      e.preventDefault();
      handleShowModal();
    }
    return (<a onClick={onOpen}  href={`/kunde/${client.slug}`}><img
    className={client.logo.file.details.image.width>client.logo.file.details.image.height?'img-landscape':'img-portrait'} 
    width={client.logo.file.details.image.width} 
    height={client.logo.file.details.image.height} 
    src={client.logo.file.url} alt={client.name} /></a>)
  }

const ClientList = ({clients}) => {
    return ( <StyledList variants={container}
        initial="hidden"
        animate="show">
        {
            clients.map((client,index)=><div key={`client_${index}`}>
              {/* <Img alt={client.name} fluid={client.logo.src} /> */}
              <ClientLogo client={client} />
            </div>)
        }
    </StyledList> );
}
 
export default ClientList;
         