
import React from "react"
import ClientList from "./clientList";
import { graphql,Link, useStaticQuery } from "gatsby"


const Clients = (props) => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulKunde (sort: {order: ASC, fields: createdAt}){
            edges {
                node {
                  name
      url
      slug
      artikel {
        title
            slug
            publishDate(formatString: "MMMM Do, YYYY")
            tags
            leistung
            template:produkt
            kunde {
              name
              id
            }
            image:heroImage {
              fluid(maxWidth: 350, maxHeight: 196, resizingBehavior: SCALE) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
            description {
              childMarkdownRemark {
                html
              }
            }
      }
      body {
        childMarkdownRemark {
          html
        }
      }
      logo {
        fluid(maxWidth: 350, maxHeight: 350, resizingBehavior: SCALE) {
          ...GatsbyContentfulFluid_withWebp
        }
        file {
          fileName
          url
          details {
            image {
              height
              width
            }
          }
        }
      }
                  
                }
            }
        }
    }
  `)

  const clients = data.allContentfulKunde.edges.map(it=>it.node);

    return ( <>
    <h2>{`Unsere Kunden &
wir`}</h2>
   
     <ClientList clients={clients.slice(0,props.showLink ? 8 : 99)} />
     {props.showLink && <Link className="show-all" to="/kunden-leistungen#kunden">Alle Kunden ansehen</Link>} 
       </> );
}
 
export default Clients;