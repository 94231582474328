

import React, { useEffect,useState } from 'react';
import styled from '@emotion/styled';


function ArrowLeft(props) {
    const disabeld = props.disabled ? " arrow--disabled" : "";
    return (
      <svg
        onClick={props.onClick}
        className={"arrow arrow--left" + disabeld}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
      >
        <path d="M 16.67 0 l 1.33 1 l -10 11 l 9.5 11.829 l -1 1 l -10.5 -12.829 z" />
      </svg>
    );
  }
  
  function ArrowRight(props) {
    const disabeld = props.disabled ? " arrow--disabled" : "";
    return (
      <svg 
        onClick={props.onClick}
        className={"arrow arrow--right" + disabeld}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
      >
        <path d="M 16.67 0 l 1.33 1 l -10 11 l 9.5 11.829 l -1 1 l -10.5 -12.829 z" />
      </svg>
    );
  }
  

const SliderArrows = ({slider,slidesPerView=1,currentSlide}) => {
    return ( <>
    {
      slider.details().size>1 && 
      <>
      <ArrowLeft
        onClick={e => e.stopPropagation() || slider.prev()}
        disabled={currentSlide === 0}
      />
      <ArrowRight
        onClick={e => e.stopPropagation() || slider.next()}
        disabled={(slidesPerView * (currentSlide)) >= slider.details().size - 1}
      />
      </>
    }
    </> );
}
 
export default SliderArrows;
