const duration = 1;
const easing = [0.6, -0.05, 0.01, 0.99];
const scaleDiff = 0.4;

const stagger = {
  animate: {
    transition: {
      staggerChildren: 1.5
    }
  }
};


const toggleVisibility = {
  visible: {
    opacity: 1,
    transition: {
      when: "beforeChildren",
      staggerChildren: 0.1,
      duration: 2,
      delay: 4
    },
  },
  hidden: {
    opacity: 0,
    transition: {
      when: "afterChildren",
      duration: 2
    },
  },
}

const fadeIn = {
  initial: {
    opacity: 0,
    transition: { duration: duration, ease: easing }
  },
  animate: {
    opacity: 1,
    transition: {
      duration: duration,
      ease: easing,
      delay:.5
    }
  }
};
const zoomIn = {
    initial: {
      scale: 1.0 + scaleDiff,
      opacity: 0,
      originX: 0.5,
      originY: 0,
      transition: { duration: duration, ease: easing }
    },
    animate: {
      scale: 1,
      opacity: 1,
      originX: 0.5,
      originY: 0,
      transition: {
        duration: duration,
        ease: easing
      }
    },
    exit: {
      scale: 1.0 - scaleDiff,
      opacity: 0,
      transition: { duration: duration, ease: easing }
    },
  };

  const defaultMotion = zoomIn;
  const defaultMotionSmall = {...defaultMotion};
  //defaultMotionSmall.initial.scale=1.002;
  //defaultMotionSmall.exit.scale=0.009;

  export {
      zoomIn,
      fadeIn,
      stagger,
      defaultMotion,
      defaultMotionSmall,
      toggleVisibility
  }