import React from 'react'

const LinkedInIcon = () => {
    return ( <svg xmlns="http://www.w3.org/2000/svg" width="28.182" height="28.37" viewBox="0 0 28.182 28.37">
    <path id="Path_141" data-name="Path 141" d="M52.953,69.512A.958.958,0,0,1,52,70.47H47.917a.958.958,0,0,1-.958-.958V52.418a.958.958,0,0,1,.958-.958H52a.958.958,0,0,1,.958.958Zm-3-19.664a3.874,3.874,0,1,1,3.874-3.874A3.874,3.874,0,0,1,49.956,49.848ZM74.264,69.589a.881.881,0,0,1-.881.881H69.007a.881.881,0,0,1-.881-.881V61.571c0-1.2.351-5.242-3.126-5.242-2.7,0-3.244,2.769-3.354,4.012v9.248a.881.881,0,0,1-.881.881H56.534a.881.881,0,0,1-.881-.881V52.34a.881.881,0,0,1,.881-.881h4.232a.881.881,0,0,1,.881.881v1.491c1-1.5,2.486-2.659,5.65-2.659,7.007,0,6.967,6.546,6.967,10.143v8.273Z" transform="translate(-46.082 -42.1)" fill="#818181"/>
  </svg>
  
   );
}
 
export default LinkedInIcon;